import React from "react";
import { Row, Col, Spin, Button, Modal, Icon, message, Tooltip } from "antd";
import dynamic from "next/dynamic";
import Router from "next/router";
import Notice from "./Notice";
import { get, post } from "$ACTIONS/TlcRequest";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { Cookie as CookieUtil, formatAmount } from "$ACTIONS/util";
import { GetAllBalance } from "$DATA/wallet";
import { getMemberInfo } from "$DATA/userinfo";
import { getQueryVariable } from "$ACTIONS/helper";
import { Cookie } from "$ACTIONS/helper";
import { connect, Provider } from "react-redux";
import { promotionActions } from "../../store/promotionSlice";
import { userCenterActions, fetchMemeberBalacnce } from "../../store/userCenterSlice";
import { gameActions } from "../../store/gameSlice";
import store from "../../store/store";
import classNames from "classnames";
import {translate} from "$ACTIONS/Translate";
import Loader from "../Wallet/loader";
import { INBOXMSG_TYPE_ID_ENUM } from "../../actions/constantsData";
import LockedBalanceModal from "../Wallet/withdrawComponents/WithdrawalVerify/LockedBalanceModal";
import Newbie from "../UI/Newbie";
import { showErrorToast, showSuccessToast } from "../../actions/helper";
import { toast, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Modal加载状态组件
const ModalLoading = (
    <Spin spinning={true} size="large" tip="加载中，请稍后..." />
);
// 财务管理
const DynamicWallet = dynamic(import("@/Wallet"), {
    loading: () => ModalLoading,
    ssr: true,
});
// 安全系统升级
const DynamicOtpPopUp = dynamic(import("@/OTP/OtpPopUp"), {
    ssr: false,
});
class HasLogged extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showType: -1, // -1 初始化 0.1 隐藏个人中心 0.2 隐藏余额 1 个人中心简介 2 余额
            showTypePosition: { x: 122, y: 166 },
            isModalLoad: false,
            loadingText: translate("上传文件中"),
            modalTabKey: {}, // showDailog的公用属性对象
            balanceLoading: true,
            walletVisible: false,
            balanceList: [],
            memberInfo: {},
            token: "",
            messageIsRead: false, // 是否有未读消息
            logoutType: "", // 8号商城登出问题
            otpVisible: false, // otp 驗證匡
            kindOfVerification: "", //是otp验证还是otp+密码验证
            isLoadOtpModal: false,
            countBalance: 0,
            selectedWalletIndex: 0,
            selectedWalletInfo: null,
            selectedWalletGroupId : -1,
            selectedWalletName: "",
            newbiePositoin: {},
            showNewbie: false,
        };

        this.otpModal = this.otpModal.bind(this); // otp modal
        this.showModal = this.showModal.bind(this); // showModal公用方法
        this.getBalance = this.getBalance.bind(this); // 获取余额
        this.showSimple = this.showSimple.bind(this); // 显示个人或者余额下拉
        this.hideSimple = this.hideSimple.bind(this); // 隐藏个人或者余额下拉
        this.closeWallet = this.closeWallet.bind(this); // 关闭钱包弹层
        this.globalExit = this.globalExit.bind(this); // 退出方法
        this.setGlobalMemberInfo = this.setGlobalMemberInfo.bind(this);
        this.setHasUnRead = this.setHasUnRead.bind(this); // 设置（Header弹窗、UserCenter）是否有未读消息
        this.checkHasReadMessage = this.checkHasReadMessage.bind(this); // 判断是否有未读消息
        this.currentModalKey = ""; // 当前显示的ModalKey
        this.delayTimer = null; // 个人或者余额下拉延迟关闭timer（防止过度闪动，高频率打开关闭！）
        this.messageIntervalTimer = null;
        this.controlExit = false; // 控制請求logout時,勿重複請求，如重複登入引起的強制登出
        global.globalExit = this.globalExit; // 全局化退出方法
        global.globalBlackListExit = this.globalBlackListExit; //黑名單專用退出
        global.setGlobalMemberInfo = this.setGlobalMemberInfo; // 全局更新会员信息（HasLogged&&UserCenter内部的memberInfo）
        global.GOGETBalance = this.getBalance.bind(this)//全局获取余额
        global.PaymentBalance = 0//全局余额
    }
    async componentDidMount() {
        if (getQueryVariable("redirectToken")) return;
        this.props.getAllBalance();
        global.showDialog = this.showModal;
        this.props.onHasLoggedEvent(this.showSimple, this.hideSimple);
        this.props.setIsWalletSetupComplete && this.props.setIsWalletSetupComplete(true);
        getMemberInfo((res) => {
            this.setGlobalMemberInfo(res);
            if (
                !localStorage.getItem("login-otp") &&
                res.isLoginOTP
            ) {
                //Login OTP流程
                this.setState({
                    isLoadOtpModal: true,
                    otpVisible: true,
                    kindOfVerification: "login-otp",
                });
            }
            if (
                !JSON.parse(localStorage.getItem("login-otpPwd")) &&
                res.revalidate
            ) {
                //Login OTP流程 + 重置密码流程
                this.setState({
                    isLoadOtpModal: true,
                    otpVisible: true,
                    kindOfVerification: "login-otpPwd",
                });
            }
        }, true);

        // 传递设置已读未读状态的方法出去、另外还需要传递设置usercenter左侧的小圆点的方法进来
        this.props.setCircleHasUnRead &&
            this.props.setCircleHasUnRead((status) => {
                this.props.setMessageHasUnRead(status);
                this.setState({ messageIsRead: status });
            });

        // 判断是否有未读消息
        this.checkHasReadMessage();
        this.messageIntervalTimer = setInterval(
            this.checkHasReadMessage,
            360000
        );
        // 默认呼出组件（开发需要）
        // this.showModal({ key: 'wallet:{"type": "withdraw", "currentPayValue": "CCW"}' })
        // this.showModal({ key: 'wallet:{"type": "deposit", "currentPayValue": "CTC"}' })
        // this.showModal({ key: 'wallet:{"type": "transfer"}' })
        // key: 'wallet:{"type": "deposit", "currentPayValue": "LB", "targetAccount": "SB", "bonusId": 103502}'
        this.checkIsSafeHouse();
    }
    componentWillUnmount() {
        global.showDialog = function () {};
        clearTimeout(this.delayTimer);
        clearInterval(this.messageIntervalTimer);
        this.setState = () => false;
    }

    setGlobalMemberInfo(res) {
        this.setState({ memberInfo: res });
        //登录OTP PASS 进行下面Ref流程
        !res.loginOTP && this.props.ReferreeTaskStatus();
        // 同步HasHeader MemberInfo 到子元素同时设置更新HasHeader MemberInfo的方法
        this.props.setUserCenterMemberInfo &&
            this.props.setUserCenterMemberInfo(
                res,
                (v) => {
                    this.setState({ memberInfo: v });
                },
                this.getBalance
            );
    }
    checkHasReadMessage() {
        get(
            ApiPort.Announcements +
                "&messageTypeOptionID=0&pageSize=1&pageIndex=1"
        ).then((res) => {
            if (res && res.result?.totalUnreadCount !== 0) {
                this.setHasUnRead(res.result.totalUnreadCount !== 0);
            } else {
                get(
                    ApiPort.InboxMessages +
                        "&messageTypeId=" + INBOXMSG_TYPE_ID_ENUM.TRANSACTION + "&messageTypeOptionIdList=0&pageSize=1&pageIndex=1"
                ).then((res) => {
                    if (res && res.result?.totalUnreadCount !== 0) {
                        this.setHasUnRead(res.result.totalUnreadCount !== 0);
                    } else {
                        get(
                            ApiPort.InboxMessages +
                                "&messageTypeId=" + INBOXMSG_TYPE_ID_ENUM.PERSONAL + "&messageTypeOptionIdList=0&pageSize=1&pageIndex=1"
                        ).then((res) => {
                            this.setHasUnRead(
                                res && res.result?.totalUnreadCount !== 0
                            );
                        });
                    }
                });
            }
        });
    }
    setHasUnRead(status) {
        this.props.setUsercnnterCircle &&
            this.props.setUsercnnterCircle(status);
        this.props.setMessageHasUnRead(status);
        this.setState({ messageIsRead: status });
    }

    getBalance(call) {
        this.setState({ balanceLoading: true });
        GetAllBalance((res) => {
            console.log("zzzzzzGetAllBalance", res);
            if (res.isSuccess) {
                res.result.map(function (item, index) {
                    window[item.name] = item.balance;
                });
                const allBalance = res.result[0].balance;
                this.props.setUserCenterMoney &&
                    this.props.setUserCenterMoney({
                        balanceList: res.result,
                        mainMoney: allBalance,
                    });
                this.props.setUserAllBalance(allBalance);
                const balance = res.result && res.result.length
                ? res.result[0].balance
                : 0
                PaymentBalance = balance
                this.setState({
                    balanceList: res.result,
                    balanceLoading: false,
                    countBalance: balance,
                });
                typeof call === "function" && call(allBalance);
            } else {
                message.error(res.errors && res.errors[0].message);
            }
        });
    }
    // 关闭钱包弹层
    closeWallet(type) {
        this.setState({ walletVisible: false });
        if (type == "ToHome") {
            if (Router.router.pathname != "/vn") {
                Router.push("/");
            }
        } else if (type == "GoBack") {
            const { gameid, launchGameCode } = Router.router.query;
            if(gameid || launchGameCode){
            Router.back();}
        }
        this.props.setOpenGame && this.props.setOpenGame();
        typeof global.openLearnDialog === "function" &&
            global.openLearnDialog();
    }
    // 呼出组件（所有弹出层窗口都可公用这个函数，需设置一级State和二级State，只需传入对应State即可！）
    showModal({ key }) {
        if (typeof key !== "string") return null;
        const keySpacer = key.indexOf(":");
        const dialogKey = key.substring(0, keySpacer);
        const keyMap = key.substring(keySpacer + 1);
        const currentParentModalMap =
            typeof keyMap !== "" ? JSON.parse(keyMap) : {}; // 当前呼出窗口携带的默认属性
        this.setState({
            [dialogKey + "Visible"]: true,
            modalTabKey: currentParentModalMap,
        });
        if(dialogKey === "wallet"){
            this.props.setRefreshCurrentPage("")
        }
    }
    showSimple(type, positionType) {
        // 因为游戏界面锁定了小Header，而此教程需要锁定大Header，因此会出现UI乱掉，需要关闭弹出此教程。
        // if (this.props.headerHeightLock !== 1) {
        //     const learnStepString = CookieUtil("learnStep");
        //     typeof learnStepString === "string" &&
        //         learnStepString.substr(2, 1) === "0" &&
        //         this.props.toggleHeaderCenterLearn(true);
        // }
        console.log("positionType",positionType)
        if( type === 2){
            if(positionType === "bottom"){
                let balanceEle = document.getElementById("wallet-center-btn-down");
                const rect = balanceEle.getBoundingClientRect();
                this.setState({
                    showNewbie: true,
                    newbiePositoin: {
                        top: rect.top + window.scrollY + rect.height, // Account for scroll and element height
                        left: rect.left + window.scrollX - 380, // Account for horizontal offset
                        btnLeft: rect.left + 80, // Account for horizontal offset
                        btnTop: rect.top + window.scrollY + rect.height + 240, // Account for scroll and element height
                    },
                });
            }else if (positionType === "top"){
            let balanceEle = document.getElementById("wallet-center-btn-top");
            const rect = balanceEle.getBoundingClientRect();
            this.setState({
                showNewbie: true,
                newbiePositoin: {
                    top: rect.top + window.scrollY - 270, // Account for scroll and element height
                    left: rect.left + window.scrollX - 380, // Account for horizontal offset
                    btnLeft: rect.left + 80, // Account for horizontal offset
                    btnTop: rect.top + window.scrollY + rect.height - 60, // Account for scroll and element height
                },
            });
            }
        }   
        clearTimeout(this.delayTimer);
        const { smarticoVisible } = this.props;
        positionType === "top" &&
            this.setState({
                showTypePosition: { x: type === 1 ?  (smarticoVisible ? 76 : 64) : (smarticoVisible ? 135 : 31), y: 48 },
            });
        positionType === "bottom" &&
            this.setState({
                showTypePosition: { x: type === 1 ? -50 : 18, y: 176 },
            });
        this.setState({ showType: type });
        this.props.onChangeShowType(type);
    }
    hideSimple(type) {
        if (this.state.showType === -1) {
            return;
        }
        this.delayTimer && clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            this.setState({ showType: type });
            this.props.onChangeShowType(0);
        }, 200);
    }

    globalExit(snapExitStatus) {
        const { memberInfo } = this.state;
        const access_token = JSON.parse(localStorage.getItem("access_token"));
        const refresh_token = JSON.parse(localStorage.getItem("refresh_token"));
        let data = {
            clientId: "FUNmuittenCN",
            clientSecret: "muitten",
            refreshToken: refresh_token,
            accessToken: access_token,
            memberCode: memberInfo.memberCode,
            packageName: "net.funpodium.tlc",
        };

        if (this.controlExit) return;
        const IsSnapExitStatus = typeof snapExitStatus === "undefined";

        this.controlExit = true;
        localStorage.getItem("access_token") !== null && this.props.LoginExit(); // 更正会员登录状态
        Modal.destroyAll(); // 清除所有弹出层
        CookieUtil(null, null);
        Cookie.Delete("emailTime"); //測試分支上無法刪除，再執行一次
        Cookie.Delete("phoneTime"); //測試分支上無法刪除，再執行一次
        Cookie.Delete("revalidateAccessCode");
        // Member LocalData
        localStorage.removeItem("affiliateUrlLM");
        localStorage.removeItem("PreferWallet");
        localStorage.removeItem("UserName");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("memberInfo");
        localStorage.removeItem("memberInfo");
        localStorage.removeItem("walletList");
        localStorage.removeItem("Address");
        // Bti LocalData
        localStorage.removeItem("ReferURL");
        localStorage.removeItem("APIUrl");
        localStorage.removeItem("MemberToken");
        localStorage.removeItem("login-otp");
        localStorage.removeItem("login-otpPwd");
        
        this.props.resetPromotionData();
        this.props.resetMemberData();
        
        global?.fbqTag && fbqTag('logout')
        // 此处为了测试退出后的一些状态时是否及时更新预留
        IsSnapExitStatus && Router.push("/").then(()=>{
            message.success(translate("您已退出登录！"));
            sessionStorage.clear();
        });
        
        if (IsSnapExitStatus) {
            if (this.state.checkSafeHouse || Cookie.Get("isLoginS")) {
                window.location.href = "/vn/safehouse";
                Cookie.Delete("isLoginS");
            } else {
                Cookie.Delete("isLoginS");
            }
        }
    }

    /*專門給黑名單使用的logout 不跳回首頁  */

    globalBlackListExit(snapExitStatus) {
        if (this.controlExit) return;
        const IsSnapExitStatus = typeof snapExitStatus === "undefined";
        this.controlExit = true;
        localStorage.getItem("access_token") !== null && this.props.LoginExit(); // 更正会员登录状态
        Modal.destroyAll(); // 清除所有弹出层
        CookieUtil(null, null);
        // Member LocalData
        localStorage.removeItem("affiliateUrlLM");
        localStorage.removeItem("PreferWallet");
        localStorage.removeItem("UserName");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("memberInfo");
        localStorage.removeItem("memberInfo");
        localStorage.removeItem("walletList");
        // Bti LocalData
        localStorage.removeItem("ReferURL");
        localStorage.removeItem("APIUrl");
        localStorage.removeItem("MemberToken");
        localStorage.removeItem("login-otp");
        localStorage.removeItem("login-otpPwd");
        sessionStorage.clear();
        IsSnapExitStatus && message.success(translate("您已退出登录！"));

        if (IsSnapExitStatus) {
            if (this.state.checkSafeHouse || Cookie.Get("isLoginS")) {
                window.location.href = "/vn/safehouse";
                Cookie.Delete("isLoginS");
            } else {
                Cookie.Delete("isLoginS");
            }
        }
    }

    checkIsSafeHouse = () => {
        let domain = window.location.origin;
        get(ApiPort.GETDomainUrl + domain)
            .then((res) => {
                console.log("isSafeHouse Domain res: ", res);
                if (res.result && res.result.isSafeHouse) {
                    this.setState({
                        checkSafeHouse: true,
                    });
                }
            })
            .catch((error) => {
                console.log("isSafeHouse Domain error: ", error);
            });
    };

    // Exit() {
    //     Modal.confirm({
    //         icon: null,
    //         centered: true,
    //         title: "确认退出",
    //         content: "确认要退出登录吗？",
    //         okButtonProps: {size: "large"},
    //         cancelButtonProps: {size: "large"},
    //         okText: "退出",
    //         cancelText: "取消",
    //         onOk: () => {
    //             this.globalExit();
    //         },
    //     });
    // }

    otpModal(v) {
        this.setState({ otpVisible: v });
    }
    render() {
        // HasLogged组件因为是在渲染之后才会render出来，所以在内部可调用dom相关事件
        const { showType, memberInfo, balanceList, countBalance, selectedWalletIndex } = this.state;
        let showShowBalanceDetail = selectedWalletIndex !== 0;
        const { smarticoVisible } = this.props;
        let userPopover, walletPopover;
        switch (showType) {
            case -1:
                (userPopover = ""), (walletPopover = "");
                break;
            case 1:
                userPopover = "show";
                break;
            case 2:
                walletPopover = "show";
                break;
            case 0.1:
                userPopover = "hide";
                break;
            case 0.2:
                walletPopover = "hide";
                break;
            default:
                break;
        }
        return (
            <React.Fragment>
                <div className="common-distance-wrap">
                    <div
                        className={`common-distance tlc-sign-header ${
                            this.props.smallHeader === "zoom-out" && "hidden"
                        }${
                            this.props.toggleCenterPopover
                                ? " user-center-popover"
                                : ""
                        }`}
                    >
                        <Row>
                            <Col span={12} className="tlc-notice-wrapper">
                                <Notice propsData={this.props} />
                            </Col>
                            <Col span={12} className="tlc-sign">
                                <div className="login-wrap">
                                    <div className="good-greeting">
                                        Hi. &nbsp; {memberInfo?.userName ? <span>{memberInfo.userName}</span> : <div className="nameLoading"/>}
                                    </div>
                                    <div className="Right-Login-Action">
                                        <div className="input-wrap">
                                            <div
                                                className={`user-center-btn${
                                                    showType === 1
                                                        ? " active"
                                                        : ""
                                                }`}
                                                onMouseEnter={() =>
                                                    this.showSimple(1, "bottom")
                                                }
                                                onMouseLeave={() =>
                                                    this.hideSimple(0.1)
                                                }
                                            >
                                                <span>{translate("个人中心")}</span>
                                                <i
                                                    className={`tlc-sprite user-message ${
                                                        this.state.messageIsRead
                                                            ? "remind-circle"
                                                            : ""
                                                    }`}
                                                />
                                                <Icon type="caret-down" />
                                            </div>
                                            <div
                                                id="wallet-center-btn-down"
                                                className={`wallet-center-btn${
                                                    showType === 2
                                                        ? " active"
                                                        : ""
                                                }`}
                                                onMouseEnter={() =>
                                                    this.showSimple(2, "bottom")
                                                }
                                                onMouseLeave={() =>
                                                    this.hideSimple(0.2)
                                                }
                                            >
                                                <span>
                                                    {formatAmount(this.props.balanceInfo?.totalBalance, true)}
                                                </span>
                                                <span>đ</span>
                                                <Icon type="caret-down" />
                                            </div>
                                        </div>
                                        <div className="BtnList">
                                            <div
                                                className="deposit-btn btnYellow "
                                                onClick={() => {
                                                    this.showModal({
                                                        key: 'wallet:{"type": "deposit"}',
                                                    });

                                                    Pushgtagdata(
                                                        "Home",
                                                        "Go to Deposit",
                                                        "Home_C_Deposit"
                                                    );
                                                }}
                                            >
                                                {translate("存款")}
                                            </div>
                                            <div
                                                className="withdraw-btn btnPurple "
                                                onClick={() => {
                                                    this.showModal({
                                                        key: 'wallet:{"type": "withdraw"}',
                                                    });

                                                    Pushgtagdata(
                                                        "Home",
                                                        "Go to Withdrawal",
                                                        "Home_C_Withdraw"
                                                    );
                                                }}
                                            >
                                                {translate("提款")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* Header头部弹窗 */}
                <div
                    className={`common-distance-wrap header-popover-wrap${
                        this.props.headerHeightLock === "has-login"
                            ? " show-hard"
                            : ""
                    }${
                        this.props.toggleCenterPopover
                            ? " has-header-center"
                            : ""
                    }`}
                >
                    <div className="common-distance">
                        <div
                            style={{
                                right: this.state.showTypePosition.x,
                                top: this.state.showTypePosition.y,
                            }}
                            className={`header-popover ${
                                this.props.headerHeightLock === "has-login" ||
                                this.props.toggleCenterPopover
                                    ? "show"
                                    : userPopover
                            }`}
                            onMouseEnter={() => this.showSimple(1)}
                            onMouseLeave={() => this.hideSimple(0.1)}
                        >
                            <div className="header-popover-content">
                                <div className="header-popover-arrow" />
                                <div className="header-popover-inner">
                                    <div className="header-popover-inner-title user-title-wrap">
                                        <div className="inline-block">
                                            <div className="user-picture-wrap">
                                                <img src={`${process.env.BASE_PATH}/img/icons/head.svg`} alt="useravatar"/>
                                            </div>
                                        </div>
                                        <div className="inline-block">
                                            <h4>{memberInfo?.userName || ""}</h4>
                                            <div className="user-info-thumb">
                                                <div className="inline-block">
                                                    <i
                                                        className={`tlc-sprite member-grade _${
                                                            this.state
                                                                .memberInfo
                                                                .levelName !==
                                                            "普通会员"
                                                                ? "1"
                                                                : "2"
                                                        }`}
                                                    />
                                                    <span>
                                                        {
                                                            this.state
                                                                .memberInfo
                                                                .levelName
                                                        }
                                                    </span>
                                                </div>
                                                <span className="inline-block">
                                                    &nbsp;
                                                </span>
                                                <div className="inline-block">
                                                    <i
                                                        className={`tlc-sprite user-email ${
                                                            this.state
                                                                .memberInfo
                                                                .isVerifiedEmail &&
                                                            this.state
                                                                .memberInfo
                                                                .isVerifiedEmail[1] &&
                                                            "curr"
                                                        }`}
                                                    />
                                                    <i
                                                        className={`tlc-sprite user-phone ${
                                                            this.state
                                                                .memberInfo
                                                                .isVerifiedPhone &&
                                                            this.state
                                                                .memberInfo
                                                                .isVerifiedPhone[1] &&
                                                            "curr"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            ghost
                                            className="user-exit"
                                            onClick={() => {
                                                //  this.Exit();
                                                this.globalExit();
                                                Pushgtagdata("Home_TopNav","Trigger Logout​​","Home_TopNav_C_Logout​");
                                            }}
                                        >
                                            {translate("退出")}
                                        </Button>
                                    </div>
                                    <ul className="header-popover-inner-content headerHoverBox">
                                        <li
                                            className="userinfo"
                                            onClick={() => {
                                                this.props.goUserCenter("userinfo");
                                                Pushgtagdata("Home_TopNav","Edit Profile​​","Home_TopNav_C_PersonalInfo​");
                                            }}
                                        >
                                            <i className="tlc-sprite user-info" />
                                            {translate("账户资料")}
                                        </li>
                                        <li
                                            className={`message${
                                                this.state.messageIsRead
                                                    ? " remind-circle"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                this.props.goUserCenter("message");
                                                Pushgtagdata("Home_TopNav","View Message​​","Home_TopNav_C_Notification​");
                                            }}
                                        >
                                            <i className="tlc-sprite message-center" />
                                            {translate("通知")}
                                        </li>
                                        <li
                                            className="records"
                                            onClick={() => {
                                                this.props.goUserCenter("records");
                                                Pushgtagdata("Home_TopNav","Go to Transaction Record​​","Home_TopNav_C_TransactionRecord​");
                                            }}
                                        >
                                            <i className="tlc-sprite record" />
                                            {translate("交易记录")}
                                        </li>
                                        <li
                                            className="bankaccount"
                                            onClick={() => {
                                                this.props.goUserCenter("bankaccount");
                                                Pushgtagdata("Home_TopNav","Go to Bank and Wallet Management​​","Home_TopNav_C_BankWallet​");
                                            }}
                                        >
                                            <i className="tlc-sprite bank-user" />
                                            {translate("账户管理")}
                                        </li>
                                        <li
                                            className="mypromotion"
                                            onClick={() => {
                                                this.props.changeTab("2");
                                                Router.push("/promotions");
                                                Pushgtagdata("Home_TopNav","Go to My Promotion​​","Home_TopNav_C_MyPromotion​");
                                            }}
                                        >
                                            <i className="tlc-sprite my-promotion" />
                                            {translate("我的优惠")}
                                            {/* 每日好礼 */}
                                        </li>
                                        <li
                                            className="betrecords"
                                            onClick={() => {
                                                this.props.goUserCenter("betrecords");
                                                Pushgtagdata("Home_TopNav","Go to Bet Record​​","Home_TopNav_C_BetRecord​");
                                            }}
                                        >
                                            <i className="tlc-sprite bet-record" />
                                            {translate("投注记录")}
                                        </li>
                                        <li
                                            className="mybonus"
                                            onClick={() => {
                                                this.props.changeTab("3");
                                                Router.push("/promotions");
                                                Pushgtagdata("Home_TopNav","Go to Rebate​​","Home_TopNav_C_Rebate​");
                                            }}
                                        >
                                            <i className="tlc-sprite my-bonus" />
                                            {translate("返水")}
                                        </li>
                                        {smarticoVisible && (
                                            <li
                                                className="smartico"
                                                onClick={() => {
                                                    Pushgtagdata(
                                                        "Home",
                                                        "Go to RewardsCentre",
                                                        "Home_C_RewardsCentre"
                                                    );
                                                    Router.push("/rewards-centre");
                                                }}
                                            >
                                                <i className="tlc-sprite smartico"/>
                                                Sảnh Tiền Thưởng
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            id="one-wallet-popover"
                            style={{
                                right: this.state.showTypePosition.x,
                                top: this.state.showTypePosition.y,
                            }}
                            className={`header-popover wallet ${walletPopover}`}
                            onMouseEnter={() => this.showSimple(2)}
                            onMouseLeave={() => this.hideSimple(0.2)}
                        >
                            <div className="header-popover-content">
                                <div
                                    className={`header-popover-arrow${
                                        this.state.balanceLoading
                                            ? " loading"
                                            : ""
                                    }`}
                                />
                                <div className="header-popover-inner">
                                    <Spin spinning={this.props.loading}>
                                        <div className="header-popover-inner-title" style={{
                                            position: "relative",
                                        }}>
                                            <div className="inline-block hover-trigger"
                                            onClick={() => {
                                                    Pushgtagdata(
                                                        "NavBar",
                                                        "Click Balance Type",
                                                        "NavBar_C_BalanceType"
                                                    );
                                                }
                                            }
                                            >
                                                <img src="/vn/img/1wallet/00/arw.svg" className="arw" />{this.props.balanceInfo?.balances[this.state.selectedWalletIndex]?.walletProductGroupName}
                                                <div style={{height:9}}>{/*gap*/}</div>
                                                <div className="lst">
                                                    {this.props.balanceInfo?.balances.map((v,i)=>{
                                                        const cur = i=== this.state.selectedWalletIndex;
                                                        return <div 
                                                            key={i}
                                                            onClick={() => {
                                                            console.log("selectedWalletIndex",i)
                                                            this.setState({
                                                                selectedWalletIndex: i,
                                                                selectedWalletGroupId : v.walletProductGroupId,
                                                                selectedWalletName : v.walletProductGroupName,
                                                                selectedWalletInfo: v,
                                                            })
                                                            Pushgtagdata(
                                                                "NavBar",
                                                                "Choose Balance Type",
                                                                "NavBar_BalanceType_C_Choose",
                                                                "",
                                                                [
                                                                    {customVariableKey: "NavBar_BalanceType_C_Choose",
                                                                    customVariableValue: v.walletProductGroupName},
                                                                ]
                                                            );
                                                        }} className={cur ? 'cur' : ''}><div>{v?.walletProductGroupName}</div></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="header-popover-inner-content">
                                            <div>
                                                <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    alignItems: "center",
                                                    marginBottom: "8px",
                                                }}
                                                >
                                                    <div
                                                    style={{
                                                        color: "#222222",
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        lineHeight: "22px",
                                                    }}
                                                    >{formatAmount(this.props.balanceInfo?.totalBalance, true) + "  đ"}</div>
                                                    <Icon
                                                    onClick={() => {
                                                        this.props.getAllBalance();
                                                        Pushgtagdata(
                                                            "NavBar",
                                                            "Refresh Balance",
                                                            "NavBar_C_RefreshBalance"
                                                        );
                                                    }}
                                                    style={{
                                                        color: "#999999"
                                                    }}
                                                    type="reload" />
                                                </div>
                                            </div>
                                            <div
                                            style={{
                                                display: (!this.props.toggleOnOff && !showShowBalanceDetail) ? "none" : "",
                                            }}
                                            > {this.state.selectedWalletIndex === 0 ?
                                                <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    color: "#222222",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "20px",
                                                    marginBottom: "8px",
                                                    paddingRight: "18px"
                                                }}
                                                >
                                                    <div
                                                    style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    >
                                                        <>
                                                            Số dư hiện
                                                            <br />
                                                            có thể rút
                                                        </>
                                                        <Tooltip
                                                            overlayClassName={"tooltip-dark"}
                                                            placement="right"
                                                            title={
                                                                this.state.selectedWalletIndex === 0 ?
                                                                "Giao dịch rút tiền sẽ được cập nhật trong vòng 15 phút sau khi cược được đặt."
                                                            :
                                                                "Có thể sử dụng để đặt cược vào bất kỳ thể loại trò chơi nào."
                                                            }
                                                        >
                                                            <span 
                                                            onClick={() => {
                                                                Pushgtagdata(
                                                                    "NavBar",
                                                                    "View Balance Tooltip",
                                                                    "NavBar_C_BalanceTooltip"
                                                                )
                                                            }
                                                            }
                                                            style={{ paddingLeft: "8px", cursor: "pointer" }}>
                                                                <img src="/vn/img/1wallet/00/info.svg" alt="Info" />
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        {formatAmount(this.props.balanceInfo?.withdrawableBalance, true) + "  đ"}
                                                    </div>
                                                </div>
                                                :
                                                <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    color: "#222222",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "20px",
                                                    marginBottom: "8px",
                                                    paddingRight: "19px"
                                                }}
                                                >
                                                    <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                    >
                                                        Số tiền chung
                                                        <Tooltip
                                                            overlayClassName={"tooltip-dark"}
                                                            placement="right"
                                                            title={
                                                                this.state.selectedWalletIndex === 0 ?
                                                                "Giao dịch rút tiền sẽ được cập nhật trong vòng 15 phút sau khi cược được đặt."
                                                            :
                                                                "Có thể sử dụng để đặt cược vào bất kỳ thể loại trò chơi nào."
                                                            }
                                                        >
                                                            <span 
                                                            onClick={() => {
                                                                Pushgtagdata(
                                                                    "NavBar",
                                                                    "View Balance Tooltip",
                                                                    "NavBar_C_BalanceTooltip"
                                                                )
                                                            }
                                                            }
                                                            style={{ paddingLeft: "8px", cursor: "pointer" }}>
                                                                <img src="/vn/img/1wallet/00/info.svg" alt="Info" />
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        {formatAmount(this.state.selectedWalletInfo?.usableAmount, true) + "  đ"}
                                                    </div>
                                                </div>
                                                }
                                                {this.state.selectedWalletIndex === 0 ?
                                                <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    color: "#222222",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "20px",
                                                    marginBottom: "8px",
                                                }}
                                                >
                                                    <div
                                                    style={{
                                                        width: "47%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    >
                                                        <div>
                                                            Tiền doanh thu 
                                                            <br />
                                                            chưa hoàn thành
                                                        </div>
                                                        <div style={{ cursor: "pointer" }}>
                                                        <Tooltip
                                                            overlayClassName={"tooltip-dark"}
                                                            placement="right"
                                                            title="Hoàn thành các yêu cầu doanh thu để mở khóa số tiền nạp đủ điều kiện và số tiền thưởng thành số tiền có thể rút."
                                                        >
                                                            <img
                                                            style={{
                                                                marginLeft: "5px",
                                                            }}
                                                            onClick={() => {
                                                                Pushgtagdata(
                                                                    "NavBar",
                                                                    "View Locked Balance Tooltip",
                                                                    "NavBar_C_LockedBalanceTooltip"
                                                                )
                                                            }
                                                            }
                                                            src="/vn/img/1wallet/00/lock.svg" />
                                                        </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div
                                                    style={{
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    onClick={() => {
                                                        if(!this.props.toggleOnOff) return
                                                        this.props?.setLockBalanceModal(true);
                                                        Pushgtagdata("NavBar","View Locked Balance Detail","NavBar_C_LockedBalance");
                                                    }}
                                                    >
                                                        {this.state.selectedWalletIndex === 0 ?
                                                        formatAmount(this.props.balanceInfo?.totalContractBalance, true) + "  đ" 
                                                        :
                                                        formatAmount(this.state.selectedWalletInfo?.lockedBalance, true) + "  đ" }
                                                        <div
                                                        style={{
                                                            paddingLeft: "8px",
                                                            width: "20px"
                                                        }}
                                                        >
                                                            <img 
                                                            style={{
                                                                display: this.props.toggleOnOff ? "" : "none",
                                                            }}
                                                            src="/vn/img/1wallet/00/arw2.svg" />
                                                        </div></div>
                                                </div>
                                                :
                                                <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    color: "#222222",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "20px",
                                                    marginBottom: "8px",
                                                }}
                                                >
                                                    <div
                                                    style={{
                                                        width: "45%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    >
                                                        <div>
                                                            Tiền thưởng KM
                                                            <br />
                                                            bị khóa
                                                        </div>
                                                        <div style={{ cursor: "pointer", marginLeft: "5px" }}>
                                                        <Tooltip
                                                            overlayClassName={"tooltip-dark"}
                                                            placement="right"
                                                            title="Số tiền bị khóa do đăng ký khuyến mãi chỉ có thể được sử dụng trong các trò chơi thuộc thể loại này."
                                                        >
                                                            <img
                                                            onClick={() => {
                                                                Pushgtagdata(
                                                                    "NavBar",
                                                                    "View Locked Balance Tooltip",
                                                                    "NavBar_C_LockedBalanceTooltip"
                                                                )
                                                            }
                                                            }
                                                            src="/vn/img/1wallet/00/lock.svg" />
                                                        </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div
                                                    style={{
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    onClick={() => {
                                                        if(!this.props.toggleOnOff) return
                                                        this.props?.setLockBalanceModal(true);
                                                        Pushgtagdata("NavBar","View Locked Balance Detail","NavBar_C_LockedBalance");
                                                    }}
                                                    >
                                                        {this.state.selectedWalletIndex === 0 ?
                                                        formatAmount(this.props.balanceInfo?.totalContractBalance, true) + "  đ" 
                                                        :
                                                        formatAmount(this.state.selectedWalletInfo?.lockedBalance, true) + "  đ" }
                                                        <div
                                                        style={{
                                                            paddingLeft: "8px",
                                                            width: "20px",
                                                            height: "20px"
                                                        }}
                                                        >
                                                            <img 
                                                            style={{
                                                                display: this.props.toggleOnOff ? "" : "none",
                                                            }}
                                                            src="/vn/img/1wallet/00/arw2.svg" />
                                                        </div></div>
                                                </div>
                                                }
                                            </div>
                        
                                        </ul>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 财务管理，DynamicWallet內有元件使用到redux，需加上	Provider以免抓不抓不到store */}
                <Modal
                    title={translate("钱包")}
                    footer={null}
                    maskClosable={false}
                    width={750}
                    className="wallet-modal"
                    visible={this.state.walletVisible}
                    onCancel={this.closeWallet}
                    destroyOnClose={true}
                    closeIcon={
                        <img
                            src={`${process.env.BASE_PATH}/img/wallet/icon-close.svg`}
                            style={{ width: "18px", height: "18px" }}
                        />
                    }
                >
                    <Provider store={store}>
                    <Loader 
                        src={`${process.env.BASE_PATH}/img/icons/loading.gif`}
                        loadingText={this.state.loadingText}
                        visible={this.state.isModalLoad}
                    />
                        {this.state.modalTabKey !== "" ? (
                            <DynamicWallet
                                dialogTabKey={this.state.modalTabKey}
                                getBalance={this.getBalance}
                                balanceList={this.state.balanceList}
                                onCancel={this.closeWallet}
                                visible={this.state.walletVisible}
                                goUserCenter={this.props.goUserCenter}
                                setModalTabKey={(v, run) =>
                                    this.setState({ modalTabKey: v }, () => {
                                        typeof run === "function" && run();
                                    })
                                }
                                setLoading={(v, text) =>
                                    this.setState({ isModalLoad: v, loadingText: text || translate("加载中") })
                                }
                                GetThroughoutVerification={() => {
                                    this.props.GetThroughoutVerification();
                                }}
                                additionalCallback = {
                                    () => {
                                        this.closeWallet("GoBack");
                                    }
                                }
                            />
                        ) : null}
                    </Provider>
                </Modal>

                {this.state.isLoadOtpModal == true ? (
                    <DynamicOtpPopUp
                        otpVisible={this.state.otpVisible} // 彈窗顯示
                        otpModal={(v) => {
                            this.otpModal(v);
                            getMemberInfo((res) => {
                                this.setGlobalMemberInfo(res);
                            }, true);
                        }} // 彈窗控制
                        otpParam={this.state.kindOfVerification} // 類別
                        memberInfo={this.state.memberInfo} // 會員信息
                        GetThroughoutVerification={() => {
                            this.props.GetThroughoutVerification();
                        }}
                    />
                ) : null}

                {<LockedBalanceModal 
                visible={this.props.showLockBalanceModal}
                onCancel={() => this.props.setLockBalanceModal(false)}
                />}

                {this.props?.toggleOnOff && this.state.showNewbie && (<Newbie imgsrc="/vn/img/1wallet/newbie/BalanceDetail.avif"  imgstyle={{width:635,top: this.state.newbiePositoin?.top || 180, left:this.state.newbiePositoin?.left || "50%",transfrom:"translateX(10px)"}} btnstyle={{top:this.state.newbiePositoin?.btnTop, left: this.state.newbiePositoin?.btnLeft,}}/>)}
            </React.Fragment>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        topTabIndex: state.promotion.topTabIndex,
        smarticoVisible: state.game.smarticoVisible,
        balance: state.userCenter.balance,
        balanceInfo : state.userCenter.balanceInfo,
        loading: state.userCenter.loading,
        showLockBalanceModal: state.userCenter.showLockBalanceModal,
        toggleOnOff : state.userCenter.toggleOnOff,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        changeTab: (i) => {
            dispatch(promotionActions.changeTab(i));
        },
        resetPromotionData: () => {
            dispatch(promotionActions.resetData());
        },
        resetMemberData: () => {
            dispatch(userCenterActions.resetData());
        },
        setRefreshCurrentPage:(v)=>{
            dispatch(userCenterActions.setRefreshCurrentPage(v))
        },
        getAllBalance: () => {
            dispatch(fetchMemeberBalacnce());
        },
        setLockBalanceModal: (bol) => {
            dispatch(userCenterActions.setShowLockBalanceModal(bol));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HasLogged);
