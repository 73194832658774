import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

export const CSS_CLASSNAME = 'newbie';
export const CSS_CLASSNAME2 = 'newbie-later';
export const IMG_BALANCE_DETAIL_NEWBIE = 'balance-dtl';

const NewbieLocalStorageKey = (imgsrc) => {
  return "NEWBIE_" + imgsrc.replace(/\//g, "").split(".")[0];
};

const Newbie = ({ imgsrc, imgstyle = {}, btnstyle = {}, isClearBg = false, isHide = false }) => {
  const KEY = NewbieLocalStorageKey(imgsrc);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the modal should be visible
    const alreadyDismissed = localStorage.getItem(KEY);
    if (!isHide && !alreadyDismissed) {
      setIsVisible(true);
      document.documentElement.classList.add("no-scroll"); // Prevent scrolling
    }

    return () => {
      document.documentElement.classList.remove("no-scroll"); // Restore scrolling on unmount
    };
  }, [KEY, isHide, isVisible]);

  const handleClose = () => {
    console.log("Close newbie");
    // Hide the modal and store the dismiss state
    console.log(document.getElementById("newbie"))
    document.getElementById("newbie").style.display = "none";
    setIsVisible(false);
    localStorage.setItem(KEY, new Date().toISOString());
    document.documentElement.classList.remove("no-scroll"); // Restore scrolling
  };

  if(!isVisible) return null;



  return ReactDOM.createPortal(
    <div
      id="newbie"
      className={isHide ? CSS_CLASSNAME2 : CSS_CLASSNAME}
      onClick={(e) => {
        if (e.target === e.currentTarget) handleClose(); // Close on backdrop click
      }}
      style={{
        position: "fixed",
        display: "block",
        zIndex: 99999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: isClearBg ? "transparent" : "rgba(0, 0, 0, 0.6)",
        backdropFilter: isClearBg ? undefined : "blur(3px)",
        WebkitBackdropFilter: isClearBg ? undefined : "blur(3px)",
      }}
    >
      <img style={{ position: "absolute", cursor: "pointer", ...imgstyle }} src={imgsrc} alt="Newbie" />
      <button
        type="button"
        onClick={handleClose} // Close on button click
        style={{
          position: "absolute",
          border: "1px solid #00A6FF",
          background: "#00A6FF",
          color: "#fff",
          lineHeight: "32px",
          height: 32,
          width: 90,
          textAlign: "center",
          cursor: "pointer",
          borderRadius: "16px",
          ...btnstyle,
        }}
      >
        Đã Hiểu
      </button>
    </div>,
    document.body
  );
};

export default Newbie;
