import { useState } from "react";

const TITLE_MAP = {
    "/": "FUN88 - Nhà cái trực tuyến tốt nhất Châu Á",
    "/the-thao/": "The Thao",
    "/esports/": "Esports",
    "/arcade-games/": "Arcade Games",
    "/arcade-games/spribe/": "Arcade Games-Spribe",
    "/arcade-games/galaxsys/": "Arcade Games-Galaxsys",
    "/live-casino/": "Live Casino",
    "/live-casino/evolution/": "Live Casino-Evolution",
    "/live-casino/pragmatic-play/": "Live Casino-Pragmatic Play",
    "/live-casino/asia-gaming/": "Live Casino-Asia Gaming",
    "/live-casino/wm-casino/": "Live Casino-Wm Casino",
    "/live-casino/dream-gaming/": "Live Casino-Dream Gaming",
    "/slots/": "Slots",
    "/slots/pragmatic-play/": "Slots-Pragmatic Play",
    "/slots/pg-soft/": "Slots-Pg Soft",
    "/slots/hacksaw/": "Slots-Hacksaw",
    "/slots/booongo/": "Slots-Booongo",
    "/slots/facai/": "Slots-Facai",
    "/slots/jili/": "Slots-Jili",
    "/slots/microgaming/": "Slots-Microgaming",
    "/slots/playtech/": "Slots-Playtech",
    "/slots/skywind/": "Slots-Skywind",
    "/slots/betsoft/": "Slots-Betsoft",
    "/slots/spadegaming/": "Slots-Spadegaming",
    "/slots/big-time/": "Slots-Big Time",
    "/slots/habanero/": "Slots-Habanero",
    "/slots/red-tiger/": "Slots-Red Tiger",
    "/slots/evoplay/": "Slots-Evoplay",
    "/xo-so/": "Xo So",
    "/promotions/": "Promotions",
    "/download-app/": "Download App",
    "/live-casino/sexy-gaming/": "Live Casino-Sexy Gaming",
    "/live-casino/gpi/": "Live Casino-Gpi",
    "/3d-casino/": "3D Casino",
    "/3d-casino/kingmidas/": "3D Casino-Kingmidas",
    "/3d-casino/kingspoker/": "3D Casino-Kingspoker",
    "/slots/cq9-gaming/": "Slots-Cq9 Gaming",
    "/slots/play-n-go/": "Slots-Play N Go",
    "/slots/ban-ca-jili/": "Slots-Ban Ca Jili",
    "/slots/fachai/": "Slots-Fachai",
    "/xo-so/tc-gaming/": "Xo So-Tc Gaming",
    "/xo-so/sg-win/": "Xo So-Sg Win",
    "/arcade-games/hot-games/": "Arcade Games-Hot Games",
    "/arcade-games/crash-games/": "Arcade Games-Crash Games",
    "/arcade-games/plinko/": "Arcade Games-Plinko",
    "/arcade-games/keno/": "Arcade Games-Keno",
    "/arcade-games/all/": "Arcade Games-All",
    "/live-casino/baccarat/": "Live Casino-Baccarat",
    "/live-casino/sicbo/": "Live Casino-Sicbo",
    "/live-casino/roulette/": "Live Casino-Roulette",
    "/live-casino/dragon-tiger/": "Live Casino-Dragon Tiger",
    "/live-casino/other-games/": "Live Casino-Other Games",
    "/live-casino/all-games/": "Live Casino-All Games",
    "/slots/ban-ca/": "Slots-Ban Ca",
    "/slots/game-khuyen-mai/": "Slots-Game Khuyen Mai",
    "/slots/hot-games/": "Slots-Hot Games",
    "/slots/arcade/": "Slots-Arcade",
    "/slots/game-kiem-tien/": "Slots-Game Kiem Tien",
    "/slots/other-games/": "Slots-Other Games",
    "/slots/all-games/": "Slots-All Games",
    "/slots/jackpot/": "Slots-Jackpot",
    "/3d-casino/poker/": "3D Casino-Poker",
    "/3d-casino/sicbo/": "3D Casino-Sicbo",
    "/3d-casino/dragon-tiger/": "3D Casino-Dragon Tiger",
    "/3d-casino/hot-games/": "3D Casino-Hot Games",
    "/3d-casino/other-games/": "3D Casino-Other Games",
    "/3d-casino/all-games/": "3D Casino-All Games",
    "/xo-so/sieu-nhanh/": "Xo So-Sieu Nhanh",
    "/xo-so/kien-thiet/": "Xo So-Kien Thiet",
    "/xo-so/all-games/": "Xo So-All Games",
    "/xo-so/gpk/": "Xo So-Game Play Interactive Keno",
    "/xo-so/slc/": "Xo So-SLC",
    "/live-casino/wec/": "Live Casino-WEC",
};

export default function seoContainer({ seocontent }) {
    // console.log("🚀 ~ file: SeoContainer.js:6 ~ seoContainer ~ seocontent:", seocontent,typeof seocontent)
    const [seoDetailStatus, setSeoDetailStatus] = useState(true);

    // Get current path and create mapping function
    const getCurrentTitle = () => {
        // Get path after domain
        const path = typeof window !== 'undefined' ? window.location.pathname : "/";
        
        // Remove leading '/vn' and query parameters, but keep trailing slash
        const normalizedPath = decodeURIComponent(path)
            .replace(/^\/vn/, '')        // Remove leading '/vn'
            .split('?')[0];              // Remove everything after '?'
        
        return TITLE_MAP[normalizedPath];
    };

    return (
        <div className="common-distance">
            <div
                className={`footer-description-wrap${
                    seoDetailStatus ? " active" : ""
                }`}
            >
                <div className="footer-header">
                    <div className="footer-header-title"> {getCurrentTitle()} </div>
                    <div 
                        className="expand-button"
                        onClick={() => {
                            setSeoDetailStatus(!seoDetailStatus);
                            // After state is updated, check if content is collapsed
                            if (seoDetailStatus) {
                                // Find the footer description element and scroll to it
                                const footerElement = document.querySelector('.footer-content-body');
                                if (footerElement) {
                                    // Scroll the content to top
                                    footerElement.scrollTop = 0;
                                }
                            }
                        }}
                    >
                        <img
                            className={seoDetailStatus ? '' : 'collapse'}
                            src={`${process.env.BASE_PATH}/img/icon/expand.svg`}
                            alt="expand"
                        />
                    </div>
                </div>
                <div
                    className={`footer-content-body${
                        seoDetailStatus ? " active" : ""
                    }`}
                    dangerouslySetInnerHTML={{ __html: seocontent }}
                />
            </div>
        </div>
    );
}
