import React from "react";
import { createForm } from "rc-form";
import { Button, Input, message, Row, Spin, Col } from "antd";
import Item from "../../components/View/Formitem";
import { pwdReg, emailReg,nameReg , affcodeReg} from "$ACTIONS/reg";
import { get, post } from "$ACTIONS/TlcRequest";
import {asycGetAffiliateReferralCode, Cookie, getDisplayPublicError,getE2BBValue } from "$ACTIONS/helper";
import { ApiPort } from "$ACTIONS/TLCAPI";
import HostConfig from "$ACTIONS/Host.config";
import CMSOBJ from "$DATA/stage.live.static.id";
import Captcha from "../Captcha";
import {translate} from "$ACTIONS/Translate";
import {
    MemberIcon,
    PasswordIcon,
    PhoneIcon,
    EmailIcon,
    RecommendIcon,
} from "./RegisterIcnoSvg";
import { getTargetPhoneRules, isValidPhone } from "../../actions/util";
import { getSeonSession } from "../../actions/util";
import LinkHelper from "@/LinkHelper";

let MAXPHONENUMBER = null;

const handlePhoneValidationUX = (value, phoneRules) => {
    let valiationResult = isValidPhone(value, phoneRules);
    if(Array.isArray(valiationResult) && Array.isArray(valiationResult[3]) && valiationResult[3].length > 0){
        MAXPHONENUMBER = valiationResult[3][1];
    }
    let errMsg;
    if (valiationResult === '_EMPTY_VALUE_') {
        errMsg = translate("请填写电话号码");
    } else if (valiationResult === '_NOT_LOADED_PHONE_RULES_YET_') {
        errMsg = translate("手机号前缀验证未加载完成");
    } else if (Array.isArray(valiationResult)) {
        let [isHitPrefix, isMinLengthGood, isMaxLengthGood, ruleOfMinAndMax] = valiationResult;
        if (!isHitPrefix) {
            errMsg = translate('此电话号码无效或属于网络运营商。');
        }
        else if (!isMinLengthGood || !isMaxLengthGood) {
            errMsg = 'Số điện thoại phải gồm có ' + ruleOfMinAndMax[1] + ' số, không điền số 0 ở đầu'
        }
    }
    return errMsg ? [valiationResult, errMsg] : undefined;
}
class _Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            UserPwd: "",
            UserPhone: "",
            EmailAccount: "",
            loading: false,
            affcode: "",
            disabled: false,
            showAffCode: true,
            captchaVisible: false,
            challengeUuid: "",
            isNameAvailable: false,
            isEmailAvailable: false,
            showEmailList: false,
            chooseTheRecommended: false,
        };

        this.phoneRules = undefined;
        this.isLoadedPhoneRules = false;
        this.phonePrefix = [];
        this.maxLength = 10;
        this.minLength = 9;
        this.emailSuffixList = [
            "@gmail.com",
            "@icloud.com",
            "@yahoo.com",
            "@yahoo.com.vn",
            "@hotmail.com",
            "@outlook.com",
            "@outlook.com.vn",
            "@caothang.edu.vn",
            "@live.com",
            "@cdktcnqn.edu.vn"
        ];
        this.emailList = [];
    }

    async componentDidMount() {
        this.props.RefRegister && this.props.RefRegister(this);
        const isDisabled =
            Cookie.GetCookieKeyValue("CO_affiliate") != "undefined" &&
            Cookie.GetCookieKeyValue("CO_affiliate") != "";
            const affCode = await asycGetAffiliateReferralCode(); // Await the affiliate code

            this.setState({
                showAffCode: !localStorage.getItem("queleaReferrerId"),
                affcode: affCode,
                disabled: isDisabled,
            });

        this.getPhonePrefix()
        global.Pushgtagpiwikurl && global.Pushgtagpiwikurl("register","Register");
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    getPhonePrefix =()=>{
        get(ApiPort.PhonePrefix)
            .then((res) => {
                this.isLoadedPhoneRules = true;
                this.phoneRules = getTargetPhoneRules(res);
            })
            .catch((error) => {
                console.log("PhonePrefix error:", error);
            });
    }
    handleInputChange = (key,value) => {
        switch (key){
            case "Username":
                this.setState({isNameAvailable: true})
                break;
            case "Email":
                this.setState({isEmailAvailable: true})
                break;
            default:
                break;
        }
    }

    startRegister = () => {
        const {
            UserName,
            UserPwd,
            UserPhone,
            EmailAccount,
        } = this.state;
        if (UserName === "") {
            message.error(translate("请输入用户名"));
            return;
        }
        if (!nameReg.test(UserName)) {
            message.error(translate("用户名长度必须至少有6个字符，不能超过14个字符，仅可使用字母 'A-Z', 'a-z' , 数字 '0-9'。"));
            return;
        }
        if (UserPwd === "") {
            message.error(translate("请输入密码"));
            return;
        }
        if (!pwdReg.test(UserPwd)) {
            message.error(translate("密码必须包含 6-20 个字母数字字符“A-Z”、“a-z”、“0-9”，并且可以包含 4 个特殊字符 ^# $@"));
            return;
        }
        
        // Updated phone number validation using checkPrefix method
        if (
            !this.isLoadedPhoneRules
        ) {
            console.error("手机号前缀验证未加载完成");
            return;
        }

        const handled = handlePhoneValidationUX(UserPhone.toString().trim(), this.phoneRules);
        if (handled) {
            let [z, s] = handled;
            if (z==='_NOT_LOADED_PHONE_RULES_YET_') {
                console.error(s)
                return;
            } else if (z==='_EMPTY_VALUE_') { // should not come here bcz empty phone is NOT able to submit
                message.error(s)
                return;
            } else if (Array.isArray(z)) {
                let [isHitPrefix, isMinLengthGood, isMaxLengthGood, ruleOfMinAndMax] = z;
                if (!isMinLengthGood || !isMaxLengthGood) {
                    message.error(s)
                    return;
                } else if (!isHitPrefix) {
                    message.error(s)
                    return;
                }
            }
        }
    
        if (!emailReg.test(EmailAccount)) {
            message.error(translate("请填写有效的电子邮件地址"));
            return;
        }
    
        if (this.Captcha) {
            this.Captcha.getCaptchaInfo(UserName);
        }
    
        if (this.state.challengeUuid === '') {
            this.setState({ captchaVisible: true });
            return;
        }
    
        this.Register();
    };
    

    /*注册*/
    Register() {
        const {
            UserName,
            UserPwd,
            UserPhone,
            EmailAccount,
            referer,
        } = this.state;
        this.setState({ loading: true });
        const UserData = {
            hostname: ApiPort.LOCAL_HOST,
            regWebsite: 37,
            language: "vi-vn",
            mobile: "84-" + UserPhone,
            email: EmailAccount,
            username: UserName,
            mediaCode: Cookie.GetCookieKeyValue("CO_Media") || null,
            referer:
                Cookie.GetCookieKeyValue("CO_Referer") ||
                sessionStorage.getItem("affCode") ||
                null,
            affiliateCode: this.state.affcode === "" ? "" : this.state.affcode,
            password: UserPwd,
            brandCode: "FUN88",
            currency: "VND",
            queleaReferrerId: localStorage.getItem("queleaReferrerId"),
            challengeUuid: this.state.challengeUuid,
            blackBoxValue:getE2BBValue(),
            deviceSignatureBlackBox: getE2BBValue(),
        };
        let responseData = {};
        global?.fbqTag && fbqTag('registerClick')
        getSeonSession().then((seonSess ) => {
            UserData.seonSession = seonSess;
            return post(ApiPort.PostRegister, UserData)
        })
            .then((res) => {
                if (res) {
                    if (res.isSuccess == true) {
                        global?.fbqTag && fbqTag('register')
                        this.props.showmodal("1");
                        this.props.login(UserName, UserPwd, "Register");
                    } else if (res.isSuccess == false) {
                        message.error(res.message);
                    }
                    responseData = res;
                }
            }).catch((error) => {
                console.log(error);
                responseData = error;
            }).finally(()=>{
                this.setState({ 
                    loading: false,
                    challengeUuid: '' 
                });
                const messages = responseData.isSuccess ? "" : getDisplayPublicError(responseData);
                let refCode = 
                    Cookie.GetCookieKeyValue("CO_Referer") ||
                    sessionStorage.getItem("affCode") ||
                    ""
                Pushgtagdata(
                    "Register",
                    "Submit Register",
                    "Register_C_Register",
                    responseData.isSuccess ? 2 : 1,
                    [
                        {
                            customVariableKey: responseData.isSuccess ? false : "Register_S_Register_ErrorMsg",
                            customVariableValue: responseData.isSuccess ? false : messages || translate("系统错误，请稍后重试！")
                        },
                        {
                            customVariableKey: responseData.isSuccess ? false : "Register_S_Register_AffiliateCode",
                            customVariableValue: responseData.isSuccess ? false : this.state.affcode
                        }
                    ]
                );
            })
    }

    RegisterFormdata = (e, t) => {
        if (t == "name") {
            this.setState({
                UserName: e.target.value,
            });
            e.target.value && nameReg.test(e.target.value) && this.handleInputChange("Username",e.target.value)
        }
        if (t == "affcode") {
            this.setState({
                affcode: e.target.value,
            });
        }
        if (t == "pwd") {
            this.setState({
                UserPwd: e.target.value,
            });
        }
     
        if (t == "phone") {
            this.setState({
                UserPhone: e.target.value,
            });
        }
        if (t == "email") {
            const atIndex = e.target.value.indexOf("@");
            let flag;
            const suffixValue = (atIndex > 0 && e.target.value.slice(atIndex+1)) || "";
            if(suffixValue){
                let tempArr = this.emailSuffixList.filter((item)=>item.startsWith("@"+suffixValue));
                if(tempArr?.length){
                    this.emailList = tempArr;
                    flag = true;
                    if (tempArr[0] === `@${suffixValue}`){
                        flag = false
                    }
                } else {
                    this.emailList = this.emailSuffixList;
                    flag = false;
                }
            }
            else {
                this.emailList = this.emailSuffixList;
                if(atIndex){
                    flag = true;
                }
                else {
                    flag = false;
                }
            }
            this.setState({
                EmailAccount: e.target.value,
                showEmailList: atIndex > 0 && flag && !this.state.chooseTheRecommended,
                chooseTheRecommended: false
            })
            e.target.value && emailReg.test(e.target.value) && this.handleInputChange("Email",e.target.value)
            let error = "";
            if(!e.target.value){
                error = translate("请输入电子邮箱")
            } else if(e.target.value && !emailReg.test(e.target.value)){
                error = translate("请填写有效的电子邮件地址")
            } 
            else if(e.target.value?.length > 50){
                error = translate("请填写最多50个字符")
            }
            this.props.form.setFields({
                emailNameState: {
                    value: e.target.value ?? "",
                    errors: [new Error(error)],
                },
            });
            this.props.form.validateFields(['emailNameState']);
        }
    };
    onMatch = (id) => {
        this.setState(
            {
                challengeUuid: id,
                captchaVisible: false,
            },
            () => {
                this.Register();
            }
        );
    };

    /**
     * @description: 如果通过验证 提交数据
     * @param {*}
     * @return {Bool} true 通过校验/false 不通过校验
     */
    submitBtnEnable = () => {
        let error = Object.values(this.props.form.getFieldsError()).some(
            (v) => v !== undefined
        );
        // console.log(
        //     "🚀 ~ _Register ~ !errors && !error && this.state.isNameAvailable && this.state.isEmailAvailable:", !errors ,
        //     "!error:", !error ,
        //     "isNameAvailable:", this.state.isNameAvailable, 
        //     "isEmailAvailable:", this.state.isEmailAvailable
        // )
        return !error && this.state.isNameAvailable && this.state.isEmailAvailable;
    };

    /**
     * 选择邮箱后缀
     * @param {Sting} item 后缀
     */
    selectMailboxSuffix=(item) => {
        const value = item.slice(1)
        const atIndex = this.state.EmailAccount.indexOf('@');
        const finalyValue = this.state.EmailAccount.slice(0, atIndex + 1);
        const emailText = finalyValue + value;
        let emailText2 = { target: { value: emailText ?? "" } };
        this.setState({
            EmailAccount: emailText,
            showEmailList: false,
            chooseTheRecommended:true,
        },
            ()=>{this.RegisterFormdata(emailText2,"email")}
        )
    }


    
    

    

    render() {
        const { 
            affcode, 
            captchaVisible,
            isNameAvailable,
            isEmailAvailable,
            UserName,
            EmailAccount,
            showEmailList,
            chooseTheRecommended,
        } = this.state;
        const { getFieldDecorator, getFieldError } = this.props.form;
        return (
            <Spin
                spinning={this.state.loading}
                tip={translate("加载中")}
                style={{ backgroundColor: "initial" }}
            >
                <div className="user-modal">
                    {/* ------------------ 用戶名 ------------------*/}
                    <Item errorMessage={getFieldError("NameState")}>
                        <div className="IputBox">
                            {getFieldDecorator("NameState", {
                                rules: [
                                    { required: true, message: translate("请输入用户名") },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (
                                                value && !nameReg.test(value)
                                            ) {
                                                callback(
                                                    translate("用户名长度必须至少有6个字符，不能超过14个字符，仅可使用字母 'A-Z', 'a-z' , 数字 '0-9'。")
                                                );
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder={translate("用户名")}
                                    prefix={<MemberIcon />}
                                    onChange={(e) =>
                                        this.RegisterFormdata(e, "name")
                                    }
                                    maxLength={14}
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 密碼 ------------------*/}
                    <Item errorMessage={getFieldError("passwordState")}>
                        <div className="IputBox">
                            {getFieldDecorator("passwordState", {
                                rules: [
                                    { required: true, message: translate("请输入密码") },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (
                                                value &&
                                                !pwdReg.test(value)
                                            ) {
                                                callback(
                                                    translate("密码必须包含 6-20 个字母数字字符“A-Z”、“a-z”、“0-9”，并且可以包含 4 个特殊字符 ^# $@")
                                                );
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input.Password
                                    size="large"
                                    prefix={<PasswordIcon />}
                                    placeholder={translate("密码")}
                                    onChange={(e) =>
                                        this.RegisterFormdata(e, "pwd")
                                    }
                                    maxLength={20}
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 联系电话 ------------------*/}
                    <Item errorMessage={getFieldError("numberState")}>
                        <div className="IputBox">
                            {getFieldDecorator("numberState", {
                               getValueFromEvent: (e) => {
                                const value = e.target.value;
                                // Remove all non-numeric characters (0-9) and spaces
                                return value.replace(/[^0-9]/g, "");
                            },
                                                          
                                rules: [
                                    {
                                        required: true,
                                        message: translate("请输入联系电话"),
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            const handled = handlePhoneValidationUX(value, this.phoneRules);
                                            console.log("handled", handled)
                                            if (handled) {
                                                let [z, s] = handled;
                                                if (z==='_NOT_LOADED_PHONE_RULES_YET_') {
                                                    callback(s);
                                                    console.error(s);
                                                    return;
                                                //} else if (z==='_EMPTY_VALUE_') {
                                                //    message.error(s)
                                                //    return;
                                                } else if (Array.isArray(z)) {
                                                    let [isHitPrefix, isMinLengthGood, isMaxLengthGood, ruleOfMinAndMax] = z;
                                                    if (!isMinLengthGood || !isMaxLengthGood) {
                                                        callback(s)
                                                    } else if (!isHitPrefix) {
                                                        callback(s)
                                                    }
                                                }
                                            }
                                            callback();
                                        },
                                    },    
                                ],
                            })(
                                <Input
                                    size="large"
                                    className="registerPhone"
                                    prefix={<PhoneIcon text={"+ 84"} />}
                                    placeholder={translate("联系电话")}
                                    onChange={(e) => this.RegisterFormdata(e, "phone")}
                                    maxLength={!!MAXPHONENUMBER ? MAXPHONENUMBER : 3}
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 电子邮箱 ------------------*/}
                    <Item errorMessage={getFieldError("emailNameState")}>
                        <div className="IputBox emailInput">
                            {getFieldDecorator("emailNameState", {
                                rules: [
                                    {
                                        required: true,
                                        message: translate("请输入电子邮箱"),
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(value && value.length > 50){
                                                callback(translate("请填写最多50个字符"));
                                            }
                                            if (value && !emailReg.test(value)) {
                                                callback(translate("请填写有效的电子邮件地址"));
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <>
                                    <Input
                                        size="large"
                                        prefix={<EmailIcon />}
                                        placeholder={translate("电子邮箱地址")}
                                        onChange={(e) =>
                                            this.RegisterFormdata(e, "email")
                                        }
                                        maxLength={60}
                                        value={EmailAccount}
                                    />
                                    {showEmailList &&
                                        <Row className="emailList">
                                        {this.emailList.map((item)=>
                                            <Col 
                                                key={item}
                                                onClick={()=>this.selectMailboxSuffix(item)}
                                            >
                                                {EmailAccount ? (EmailAccount.slice(0, (EmailAccount.indexOf('@'))) + item) : item}
                                            </Col>
                                        )}
                                   </Row>
                                    }
                                </>
                               
                            )}
                        </div>
                    </Item>
                    {/* ------------------ 推荐代码 ------------------*/}
                    {this.state.showAffCode ? (
                    <Item errorMessage={getFieldError("affcodeState")}>
                        <div className="IputBox fix-not-required-text">
                            {getFieldDecorator("affcodeState", {
                                initialValue: affcode,
                                rules: [
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value && !affcodeReg.test(value)) {
                                                callback(translate("推荐代码必须为6位数字"));
                                            }
                                            callback();
                                        },
                                    }
                                ]
                            },)(
                                <Input
                                    size="large"
                                    prefix={<RecommendIcon />}
                                    placeholder={translate("推荐代码")}
                                    disabled={this.state.disabled ? true : false}
                                    key={this.state.disabled ? affcode : ""}
                                    onChange={(e) =>
                                        this.RegisterFormdata(e, "affcode")
                                    }
                                    maxLength={6}
                                />
                            )}
                            <div className="not-required-text">{translate("非必填")}</div>
                        </div>
                    </Item>
                    ) : null}

                    {/* ------------------ 注册 ------------------*/}
                    <Button
                        size="large"
                        type="primary"
                        block
                        onClick={() => {
                            this.startRegister();
                        }}
                        disabled={!this.submitBtnEnable()}
                    >
                        {translate("注册")}
                    </Button>
                </div>

                <div className="protocol-box">
                    <p className="protocol">
                        {translate("点击“注册”按钮即表示您已年满 21 岁并同意")}
                        <LinkHelper
                            href="/help/gamble-responsibly"
                            onClick={() => {
                                console.log("🚀 ~ file: Register.js:575 ~ _Register ~ render ~ CMSOBJ[HostConfig.CMS_ID][25]:", CMSOBJ[HostConfig.CMS_ID][25]);
                                Pushgtagdata("Register","View TC","Register_V_T&C");
                            }}
                        >
                            {translate("条款")}
                        </LinkHelper>
                        {translate("与")}
                        <br/>
                        <LinkHelper
                            href="/help/privacy-policy"
                            onClick={() => {
                                Pushgtagdata("Register","View TC","Register_V_T&C");
                            }}
                        >
                            {translate("隐私政策")}
                        </LinkHelper>
                        {translate("我们的。")}
                    </p>
                </div>

                <Captcha
                    captchaVisible={captchaVisible}
                    setCaptchaVisible={(v) => {
                        this.setState({ captchaVisible: v });
                    }}
                    onMatch={this.onMatch}
                    type="Register"
                    getCaptchaInfo={(props) => {
						this.Captcha = props;
					}}
                />
            </Spin>
        );
    }
}

export default createForm({ fieldNameProp: "register" })(_Register);
