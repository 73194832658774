import Head from "next/head";
import dynamic from "next/dynamic";
import Header from "@/Header";
import Footer from "@/Footer";
import { Spin } from "antd";
import "../../config/Global";
import "../../config/Globalfun";
import React, { useState, useEffect } from "react";
import SelfExclusionModal from "../SelfExclusionModal";
import Router from "next/router";
import { translate } from "$ACTIONS/Translate";
import seoStatic from '$DATA/seo/static';
import { get } from "$ACTIONS/TlcRequest";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { AvifCheckerModule, isWebPSupported } from "$ACTIONS/helper";
import HostConfig from "../../actions/Host.config";

const DynamicRestrictAccess = dynamic(import("@/RestrictAccess/ipError"), {
    loading: () => (
        <Spin
            style={{ position: "absolute", top: "30%", left: 0, right: 0 }}
            spinning={true}
            size="large"
            tip={translate("加载中")}
        />
    ),
    ssr: false,
});

const DynamicMaintain = dynamic(import("@/RestrictAccess/maintain"), {
    loading: () => (
        <Spin
            style={{ position: "absolute", top: "30%", left: 0, right: 0 }}
            spinning={true}
            size="large"
            tip={translate("加载中")}
        />
    ),
    ssr: false,
});
function MainComponent(props) {
    /* 
        HasLogged => 設置 isWalletSetupComplete 來判斷 showModal 已設置完成，避免開啟遊戲確認餘額時 global.showDialog 為空
        NotLogged => isWalletSetupComplete 直接設為 true
    */
    const [isWalletSetupComplete, setIsWalletSetupComplete] = useState(false);
    const commonParams = {
        headerHeightLock: props.headerHeightLock,
        setLockHeader: props.setLockHeader,
        setLoginStatus: props.setLoginStatus,
        setCircleHasUnRead: props.setCircleHasUnRead,
        setUsercnnterCircle: props.setUsercnnterCircle,
        setUserCenterMoney: props.setUserCenterMoney,
        setUserCenterMemberInfo: props.setUserCenterMemberInfo,
        getPromotionList: props.getPromotionList,
        definedHeaderNode: props.definedHeaderNode,
        setOpenGame: props.setOpenGame,
    };
    // global.HttpStatus =4;
    const globalStatusKey = global.HttpStatus || props.status || 1;

    const makeFooterDataValue = (isAvif, isWebp, o={}) => [{isAvif, isWebp}, o];
    const [footerData, setFooterData] = useState(makeFooterDataValue(false, false, {}));
    useEffect(() => {
        AvifCheckerModule.isAvifSupportedPromise().then(b => {
            get(ApiPort.GETFooter).then(res => {
                setFooterData(makeFooterDataValue(b, isWebPSupported(), res?.data || {}))
            })
        });
    }, [])

    switch (globalStatusKey) {
        case 1:
            return (
                <div
                    className={`tlc-container-wrapper ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    } ${
                        props.headerHeightLock
                            ? "_" + props.headerHeightLock
                            : ""
                    }`}
                >
                    <Header key="main-header" {...commonParams} />
                    {props.children}
                    <Footer
                        isIndexPage={props.isIndexPage}
                        key="main-footer"
                        footerData={footerData}
                        seoFooterContent={props.seoContainer}
                        showFooter={props.showFooter}
                    />
                </div>
            );
        case 2:
            return (
                <div
                    className={`tlc-container-wrapper ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    } ${
                        props.headerHeightLock
                            ? "_" + props.headerHeightLock
                            : ""
                    }`}
                >
                    <Header key="main-header" {...commonParams} />
                    {props.children}
                </div>
            );
        case 3:
            return <DynamicMaintain />;
        case 4:
            return <DynamicRestrictAccess httpStatus={global.HttpStatus} />;
        case 5:
            return <React.Fragment>{props.children}</React.Fragment>;
        case 6:
            return (
                <div
                    className={`tlc-container-wrapper-Diamond ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    } ${
                        props.headerHeightLock
                            ? "_" + props.headerHeightLock
                            : ""
                    }`}
                >
                    <Header key="main-header" {...commonParams} />
                    {props.children}
                    <Footer key="main-footer" footerData={footerData} />
                </div>
            );
        case 7:
            return (
                <div
                    className={`tlc-container-wrapper _1 ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    }`}
                >
                    <Header key="main-header" {...commonParams} setIsWalletSetupComplete={(val) => {
                            setIsWalletSetupComplete(val);
                    }}/>
                    {isWalletSetupComplete ? props.children : null}
                    <Footer key="main-footer" footerData={footerData} />
                </div>
            );
        case 8:
            return <div className={`${props.wrapperClassName}`}>
                <div style={{ display: 'none', opacity: 0, width: 0, height: 0 }}>
                    <Header key="main-header" {...commonParams} />
                </div>
                {props.children}
                <Footer key="main-footer" footerData={footerData}/>
            </div>
        default:
            return <div>500 Error!</div>;
    }
}

export default ({
    isIndexPage,
    status,
    // 默认需要的内容
    children,
    // 锁定Header状态栏大小
    setLockHeader,
    // 设置登陆状态
    setLoginStatus,
    // 锁定Header状态栏大小所需要的配置参数
    headerHeightLock,
    // 设置Header部分未读标识（小红点）
    setCircleHasUnRead,
    // 设置UserCenter部分未读标识（小红点）
    setUsercnnterCircle,
    // 设置UserCenter的钱
    setUserCenterMoney,
    // 设置UserCenter的会员信息
    setUserCenterMemberInfo,
    // 顶层样式表名称
    wrapperClassName,
    // 自定义Header
    setOpenGame,
    definedHeaderNode,
    title = "Nhà cái Fun88 - Link đăng nhập Website chính thức Fun88",
    Keywords = "",
    description = "Nhà cái uy tín Funvn66.com ✔️ Link website chính thức của Fun88 cung cấp hệ thống cá cược thể thao, casino, slot game... ưu đãi khủng.",
    getPromotionList,
    seoData,
    showFooter = true,
    linkHref=""
}) => {
    useEffect(() => {
        /**
         * Seon 在 Local端init時會隨機出現webpack__require錯誤，經嘗試無法排除，
         * 為避免影響開發，只在非Local環境執行seon.init
         */
        if (
            !document.getElementById("seonScriptTag")
        ) {
            setTimeout(async () => {
                const HTML_ID = "seonScriptTag";
                if (!document.getElementById(HTML_ID)) {
                    const scriptLink = document.createElement("script");
                    scriptLink.id = HTML_ID;
                    scriptLink.src =
                        "https://cdn.seondf.com/js/v6/agent.umd.js";
                    scriptLink.type = "text/javascript";
                    scriptLink.onload = () => {
                        if (window?.seon && !HostConfig.Config.isLocalEnv) {
                            window.seon.init();
                        }
                    };
                    document
                        .querySelectorAll("head")[0]
                        .appendChild(scriptLink);
                }
            });
        }
    }, []);

    return(
        [<Head key="layout-head">
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
            <link rel="icon" href="/vn/favicon.ico"/>
            {(seoData?.seoHead) && (
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: seoData.seoHead }}
            />
            )}
            {(seoData?.globalHead) && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: seoData.globalHead }}
                />
            )}
            <title>{seoData?.title ?? title}</title>
            <meta name="description" content={seoData?.description ?? description} />
            <meta name="Keywords" content={seoData?.keyword ?? Keywords} />
            <meta name="twitter:card" content={seoData?.twitterCard ?? "twitter card unset"} />
            <meta name="twitter:description" content={seoData?.twitterDescription ?? `${seoStatic.mainDomain} twitter description unset`} />
            <meta name="twitter:title" content={seoData?.twitterTitle ?? "twitter title unset"} />
            <meta name="footer" content={seoData?.seoContent ?? "footer unset"} />
            <link rel="canonical" href={seoData?.canonicalHref ?? (linkHref || `https://www.${seoStatic.mainDomain}/vn/`)} />
            <link rel="alternate" hrefLang="vi-vn" href={seoData?.alternateHref ?? `https://www.${seoStatic.mainDomain}/vn`} />
        </Head>,
        <>
            <MainComponent
                key="main-component"
                isIndexPage={isIndexPage}
                status={status}
                setLockHeader={setLockHeader}
                setLoginStatus={setLoginStatus}
                wrapperClassName={wrapperClassName}
                headerHeightLock={headerHeightLock}
                setCircleHasUnRead={setCircleHasUnRead}
                setUsercnnterCircle={setUsercnnterCircle}
                setUserCenterMoney={setUserCenterMoney}
                setUserCenterMemberInfo={setUserCenterMemberInfo}
                children={children}
                getPromotionList={getPromotionList}
                definedHeaderNode={definedHeaderNode}
                seoContainer={seoData?.seoContent}
                showFooter={showFooter}
                setOpenGame={setOpenGame}
            />
            <SelfExclusionModal
                ModalType={1}
                OpenModalUrl="Home"
                afterCloseModal={() => {
                    Router.push("/");
                }}
            />
        </>])
};
