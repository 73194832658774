import { Modal, Table, Tooltip, Progress, Pagination, Typography } from "antd";
import { render } from "less";
import React, { useEffect, useState } from "react";
import {
    BonusProgressDataHandler,
    GetBonusProgress,
} from "../../../../data/wallet";
import { formatAmount } from "../../../../actions/util";
import Newbie from "../../../UI/Newbie";
import { connect } from "react-redux";
import moment from "moment";

function LockedBalanceModal({
    visible,
    content,
    onCancel,
    toggleOnOff = false,
}) {
    const [bonusProgress, setBonusProgress] = useState({});
    const [bonusProgressData, setBonusProgressData] = useState([]);
    useEffect(() => {
        if(!visible) return;
        GetBonusProgress().then((res) => {
            console.log("GetBonusProgress", res);
            if (res && res.isSuccess) {
                setBonusProgress(res.result);
                BonusProgressDataHandler(res.result, (data) => {
                    console.log("BonusProgressDataHandler", data);
                    setBonusProgressData(data);
                });
            }
        });
    }, [visible]);
    const columns = [
        {
            title: "Danh mục trò chơi",
            dataIndex: "category",
            key: "category",
            width: "17%",
        },
        {
            title: "Tên thưởng",
            dataIndex: "bonusName",
            key: "bonusName",
            width: "12%",
        },
        {
            title: (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Align text centrally
                        justifyContent: "center", // Center content vertically
                        textAlign: "center", // Ensure text alignment
                    }}
                >
                    Thời gian trả
                    <br />
                    Thưởng
                    <br />
                    <span
                        style={{
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#888", // Optional: Use a lighter color for clarity
                        }}
                    >
                        (GMT+8)
                    </span>
                </div>
            ),
            dataIndex: "bonusTime",
            key: "bonusTime",
            width: "18%",
            render: (time, record) => (
                <div>
                    <div
                        style={{
                            display: "flex",
                        }}
                    >
                        <span
                            style={{
                                width: "53%",
                            }}
                        >
                            {time}
                        </span>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {record?.expiredDateTime && <Tooltip
                                overlayClassName={"tooltip-dark"}
                                title={`Thời gian kết thúc: ${record?.expiredDateTime}`}
                            >
                                <span style={{ cursor: "pointer" }}>
                                    <img
                                        src="/vn/img/1wallet/00/info.svg"
                                        alt="Info"
                                    />
                                </span>
                            </Tooltip>}
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "Số tiền thưởng",
            dataIndex: "bonusAmount",
            key: "bonusAmount",
            width: "12%",
            render: (amount) => <Typography.Text>{amount} đ</Typography.Text>,
        },
        {
            title: (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Align text centrally
                        justifyContent: "center", // Center content vertically
                        textAlign: "center", // Ensure text alignment
                    }}
                >
                    Tiền doanh thu
                    <br />
                    chưa hoàn thành
                </div>
            ),
            dataIndex: "neededAmount",
            key: "neededAmount",
            width: "16%",
            render: (neededAmount) => (
                <Typography.Text>{neededAmount} đ</Typography.Text>
            ),
        },
        {
            title: (
                <div
                    style={{
                        width: "100%",
                        height: "100%", // Make sure it occupies the full height of the header cell
                        display: "flex", // Use Flexbox for alignment
                        justifyContent: "center", // Center horizontally
                        alignItems: "center", // Center vertically
                        textAlign: "center", // Ensure text alignment
                    }}
                >
                    Tiến độ
                </div>
            ),
            key: "progress",
            width: "28%",
            render: (text, record) => (
                <div className="progressDiv">
                    <Tooltip title="Hoàn thành yêu cầu doanh thu để mở khóa số tiền bạn có thể rút.">
                        <span style={{ cursor: "pointer" }}>
                            Tiến độ Doanh thu
                        </span>
                        <div
                            style={{
                                color: "#999999",
                            }}
                        >
                            (Hoàn thành yêu cầu doanh thu để mở khóa số tiền bạn
                            có thể rút)
                        </div>
                    </Tooltip>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                color: "#999999",
                                fontSize: "12px",
                                lineHeight: "16px",
                                fontWeight: "400",
                            }}
                        >
                            <div>
                                <span
                                    style={{
                                        color: "#00a6ff",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {record?.currentTurnover}{" "}
                                </span>
                                / {record?.requiredTurnover}
                            </div>
                            <div>{record.progress} %</div>
                        </div>
                        <Progress
                            className="1wallet bonusProgress"
                            showInfo={false}
                            percent={record.progress}
                        />
                    </div>
                </div>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            category: "Tất cả các loại",
            bonusName: "Thưởng Hoàn trả",
            bonusTime: "24/12/2021 17:39",
            bonusAmount: 500,
            progress: 25,
        },
        // More rows...
    ];
    return (
        <Modal
            visible={visible}
            footer={null}
            closable={true}
            closeIcon={
                <img src="/vn/img/1wallet/00/closeIcon.svg" alt="Close" />
            }
            className="oneWalletModal locked-balance-modal"
            id="locked-balance-modal"
            width={1200}
            onCancel={onCancel}
        >
            <div
                style={{
                    width: "100%",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    <h2>Tiền doanh thu chưa hoàn thành</h2>
                </div>
            </div>
            <div>
                <div>
                    <div
                        style={{
                            color: "#666666",
                            fontSize: "16px",
                            lineHeight: "22px",
                            fontWeight: "600",
                        }}
                    >
                        Tổng số tiền Doanh thu chưa hoàn thành
                    </div>
                    <div>
                        Hoàn thành các yêu cầu doanh thu để mở khóa số tiền nạp
                        đủ điều kiện và số tiền thưởng thành số tiền có thể rút.
                    </div>
                    <div
                        style={{
                            color: "#00A6FF",
                            fontSize: "20px",
                            fontWeight: "600",
                            lineHeight: "24px",
                        }}
                    >
                        {formatAmount(bonusProgress?.totalContractBalance)} đ
                    </div>
                    <div
                        style={{
                            backgroundColor: "#FFFFFF",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            padding: "16px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                // justifyContent: "space-between",
                                gap: "8px",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    color: "#666666",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: "600",
                                }}
                            >
                                Gửi tiền bị khóa
                            </div>
                            <div
                                style={{
                                    color: "#999999",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    fontWeight: "400",
                                }}
                            >
                                Hoàn thành doanh thu 1x để mở khóa số tiền có
                                thể rút
                            </div>
                        </div>
                        <div>
                            <div
                                style={{
                                    color: "#00A6FF",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                }}
                            >
                                <span>
                                    <img
                                        src="/vn/img/1wallet/00/lock.svg"
                                        alt="Lock"
                                    />
                                </span>
                                <span
                                    style={{
                                        paddingLeft: "8px",
                                        color: "#222222",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        lineHeight: "22px",
                                    }}
                                >
                                    {formatAmount(
                                        bonusProgress?.totalDepositContractBalance
                                    )}{" "}
                                    đ
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                color: "#999999",
                                fontSize: "12px",
                                lineHeight: "16px",
                                fontWeight: "400",
                            }}
                        >
                            <div>
                                Thời gian cập nhật cuối cùng:{" "}
                                <span>
                                    {moment(moment.now()).format(
                                        "DD/MM/YYYY HH:mm"
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: "#FFFFFF",
                        padding: "16px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            // justifyContent: "space-between",
                            alignItems: "center",
                            gap: "8px",
                            padding: "16px",
                            borderBottom: "1px solid #E5E5E5",
                            color: "#666666",
                            fontSize: "16px",
                            lineHeight: "22px",
                            fontWeight: "600",
                        }}
                    >
                        <div>
                            Thưởng bị khóa
                            <span
                                style={{
                                    paddingLeft: "8px",
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    src="/vn/img/1wallet/00/lock.svg"
                                    alt="Lock"
                                />
                            </span>
                        </div>
                        <div>
                            {formatAmount(
                                bonusProgress?.totalContractBalance -
                                    bonusProgress?.totalDepositContractBalance
                            )}{" "}
                            đ
                        </div>
                    </div>
                    <Table
                        className="lockedBalancePage locked-balance-table"
                        dataSource={bonusProgressData}
                        columns={columns}
                        pagination={
                            bonusProgressData.length > 5
                                ? {
                                      className: "centered-pagination",
                                      pageSize: 5,
                                      itemRender: (
                                          current,
                                          type,
                                          originalElement
                                      ) => {
                                          if (type === "prev") {
                                              return (
                                                  <div>
                                                      <span>
                                                          <img
                                                              style={{
                                                                  filter: "invert(86%) sepia(7%) saturate(162%) hue-rotate(192deg) brightness(95%) contrast(90%)",
                                                              }}
                                                              src="/vn/img/1wallet/newbie/paginationLeftArrow.svg"
                                                          />
                                                      </span>
                                                  </div>
                                              );
                                          }
                                          if (type === "next") {
                                              return (
                                                  <div>
                                                      <span>
                                                          <img
                                                              style={{
                                                                  width: "17px",
                                                                  height: "17px",
                                                                  filter: "invert(60%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(60%) contrast(100%)",
                                                              }}
                                                              src="/vn/img/1wallet/newbie/paginationRightArrow.svg"
                                                          />
                                                      </span>
                                                  </div>
                                              );
                                          }
                                          return originalElement;
                                      },
                                  }
                                : false
                        }
                        locale={{
                            emptyText: "Hiện không có ghi nhận nào về Thưởng. ",
                        }}
                    />
                </div>
                {content}
            </div>
            <Newbie
                imgsrc="/vn/img/1wallet/newbie/LockedBalance.avif"
                imgstyle={{
                    width: "900px",
                    top: 100,
                    left: "20%",
                    transform: "translateX(-2%)",
                }}
                btnstyle={{
                    bottom: "3%",
                    right: "50%",
                    transform: "translateX(50%)",
                }}
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    balanceInfo: state.userCenter.balanceInfo, // Retrieve balance info from Redux state
    toggleOnOff: state.userCenter.toggleOnOff, // Retrieve toggleOff from Redux state
});

export default connect(mapStateToProps)(LockedBalanceModal);
